import React from "react"
import { Heading} from 'theme-ui'
import styled from '@emotion/styled'
import {useI18next} from 'gatsby-plugin-react-i18next';

const styles = {
    langSelector: {
      border: `transparent !important`,
      fontSize: `1rem`,
      fontWeight: `normal`
    }
  }

const LangSelector = styled.select`
    border: transparent;
    background: transparent;
    font-size: 1rem;
    color: inherit;
    &:focus {
        border: transparent;
        border-color: transparent;
        outline: none;
        font-size: 1rem;
    }
    &:active {
        border: transparent;
        border-color: transparent;
        outline: none;
        font-size: 1rem;
    }
    `

export const LanguageSelector = ({ ...props}) => {
    const {language, languages, changeLanguage} = useI18next();
    //const context = React.useContext(I18nextContext);
    return (
        <Heading>
            <LangSelector onChange={(e) => {
                changeLanguage(e.target.value)
              }} defaultValue={language}>
                <option value='de'>Deutsch</option>
                <option value='en'>English</option>
            </LangSelector>
        </Heading>
    )
}

export default LanguageSelector