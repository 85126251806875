import React, { useContext } from 'react'
import { Container, Box, Flex, NavLink } from 'theme-ui'
import pageContextProvider from '@helpers/pageContextProvider'
import Search from '@widgets/Search'
import { HeaderLogo } from '../../../../../packages/flow-ui/flow-ui-layout/src/Header/Header.Logo'
import { HeaderMenu } from '../../../../../packages/flow-ui/flow-ui-layout/src/Header/Header.Menu'
import { HeaderColorMode } from '../../../../../packages/flow-ui/flow-ui-layout/src/Header/Header.ColorMode'
import styled from '@emotion/styled'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `headerBg`
  },
  container: {
    position: `relative`,
    zIndex: 10
  },
  containerMainMenu: {
    '@media screen and (min-width: 640px)':{
      paddingTop: `0 !important`,
      paddingBottom: `0 !important`,
      height: `4.5rem !important`
    }
  },
  containerCallAction: {
    borderBottom: `1px solid`
  },
  logoContainer: {
    flexBasis: [`full`, null, `1/3`]
  },
  searchContainer: {
    flexBasis: [`auto`, null, `0/3`],
    minWidth: `auto`,
    order: [3, null, `unset`],
    mx: 3
  },
  menuContainer: {
    flexBasis: [`auto`, null, `2/3`],
    minWidth: `auto`,
    order: [4, null, `unset`]
  },
  colorModeContainer: {
    minWidth: `auto`,
    order: [2, null, `unset`]
  },
  navCallAction:{
    display: `flex`,
    alignItems: `right`,
    justifyContent: `flex-end`,
    ml: `auto`,
    //borderBottom: `1px solid`
  },
  navicon:{
    m: `0 1rem 0 0`,
    padding: `0.25rem`,
    cursor: `pointer`,
    ml: 40,
    display: `inline-block`,
    fontWeight: `inherit`,
    'svg': {
      display: `inline-block`,
      width: `1rem`,
      height: `1rem`,
      mr: `7px`,
      mb: `-4px`,
    }
  }
}

export const Header = ({ children }) => {
  const context = useContext(pageContextProvider)

  const { services, mobileMenu, darkMode } = context.pageContext

  const algolia = services && services.algolia

  return (
    <Box sx={styles.wrapper}>
      <Container variant='full' sx={styles.container}>
        <Flex sx={styles.containerCallAction}>
          <Box sx={styles.navCallAction}>
              {
              <NavLink href="tel:01633717190"  sx={styles.navicon}>
                <svg xmlns="http://www.w3.org/2000/svg" class="navicon h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /> </svg>
                +49 (0)163 3717 190 
              </NavLink>}
              <NavLink  href="mailto:contact@sop-it.de" sx={styles.navicon}>
                <svg xmlns="http://www.w3.org/2000/svg" class="navicon h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" /> </svg>
                contact@sop-it.de
              </NavLink>
          </Box>
        </Flex>
      </Container>
      <Container variant='compact' sx={styles.container}>
        <Flex variant='layout.header' sx={styles.containerMainMenu}>
          <Box sx={styles.logoContainer}>
            <HeaderLogo />
          </Box>
          <Box sx={styles.searchContainer}>{algolia && <Search />}</Box>
          <Box sx={styles.menuContainer}>
            <HeaderMenu mobileMenu={mobileMenu} />
          </Box>
        </Flex>
      </Container>
      {children}
    </Box>
  )
}
