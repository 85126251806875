import {React, useContext} from 'react'
import pageContextProvider from '@helpers/pageContextProvider'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import Section from '@components/Section'
import { Container, Heading, Box, Flex } from 'theme-ui'
import { FooterLogo } from './Footer.Logo'
import { FooterMenu } from '../../../../../packages/flow-ui/flow-ui-layout/src/Footer/Footer.Menu'
import { FooterSocial } from '../../../../../packages/flow-ui/flow-ui-layout/src/Footer/Footer.Social'
import { HeaderColorMode } from '../../../../../packages/flow-ui/flow-ui-layout/src/Header/Header.ColorMode'
import { LanguageSelector } from "../../flow-ui-components/LanguageSelector/LanguageSelector"

import BadgeList from '../../flow-ui-components/BadgeList'

const styles = {
  conatiner:{
    mt: [5, 6],
    position: `relative`,
  },
  wrapper: {
    bg: `footerBg`,
    mt: [5, 3]
  },
  badgeSectionTitle: {
    'text-align': "center",
    margin: 'auto',
    with: '90%',
    fontSize: '1.2em'
  },
  colorModeContainer: {
    minWidth: `auto`,
    order: [2, null, `unset`]
  }
}

export const Footer = () => {
  const context = useContext(pageContextProvider)
  const { services, mobileMenu, darkMode } = context.pageContext
  const { t } = useTranslation()
  return (
    <Box sx={styles.conatiner}>
    <Box>
      <Box sx={styles.badgeSectionTitle}><Trans>We builds systems that are: Secure, Compliant, and guaranteed Customer Satisfaction.</Trans></Box>
      <BadgeList/>
    </Box>
    <Box sx={styles.wrapper}>
      <Container variant='compact' sx={styles.container}>
        <Flex variant='layout.footer'>
          <Box>
            <FooterLogo />
          </Box>
          <FooterMenu />
          <Box>
            <FooterSocial />
          </Box>
          <Box sx={styles.colorModeContainer}>
            {darkMode && <Heading><HeaderColorMode /></Heading>}
            <LanguageSelector />
          </Box>
        </Flex>
      </Container>
    </Box>
    </Box>
  )
}
