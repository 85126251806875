import React from 'react'
import { useColorMode, Box } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Logo from '@components/Logo'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const styles = {
    badgeContainer: {
      display: `inline-block`,
      margin: `0 15px`
    },
    box: {
        display: `flex`,
        justifyContent: `center`,
        marginTop: `15px`
    }
  }

const BadgeList = ({ ...props }) => {
  const title = "partner-logo"

  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const { logo, logoDark } = useStaticQuery(badgesQuery)
  
  const logoNormal = logo.edges
  const LogoDark = logoDark.edges

  if (!logoNormal) return null

  return (isDark && LogoDark) ? (
      
    <>
    <Box variant="primary" {...props} __themeKey="text" style={styles.box}>
        {LogoDark.map((item, i) => {
            return <Logo image={getImage(item.node)} ignoreLink={true} title={title} alt={title} {...props} style={styles.badgeContainer} />
        })}
    </Box>
    </>
    ) : (
    <>
    <Box variant="primary" {...props} __themeKey="text" style={styles.box}>
        {logoNormal.map((item, i) => {
            return <Logo image={getImage(item.node)} ignoreLink={true} title={title} alt={title} {...props} style={styles.badgeContainer} />
        })}
    </Box>
    </>
  )
}

export default BadgeList


const badgesQuery = graphql`
  query badgesQuery {
    logo: allFile(filter: {
        extension: {eq: "png"},
        sourceInstanceName: {eq: "asset"},
        absolutePath: { regex: "/badges/badge-([a-z]*).(jpeg|jpg|gif|png)/" }
      })
    {
      edges {
        node {
        childImageSharp {
          gatsbyImageData(
            width: 130
            layout: CONSTRAINED
            quality: 100
            placeholder: NONE
          )
        }
        }
      }
    }
    
    logoDark: allFile(filter: {
        extension: {eq: "png"},
        sourceInstanceName: {eq: "asset"},
        absolutePath: { regex: "/badges/badge-([a-z]*).(jpeg|jpg|gif|png)/" }
      })
    {
      edges {
        node {
        childImageSharp {
          gatsbyImageData(
            width: 130
            layout: CONSTRAINED
            quality: 100
            placeholder: NONE
          )
        }
        }
      }
    }
  }
`
